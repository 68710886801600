<template>
	<div class="vg_wrapper">
		<el-card>
			<div class="vd_search_group">
				<el-form :inline="true" label-width="120px" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getTableData()">
					<el-row>
						<!-- <el-col :md="8">
							<el-form-item label="客户货号:" label-width="114px">
								<el-input size="small" v-model.trim="searchForm.cust_artno" clearable placeholder="请填写客户货号"/>
							</el-form-item>
						</el-col> -->
						<el-col :md="8">
							<!-- <el-form-item label="采购号码:">
								<el-input size="small" v-model.trim="searchForm.podr_no" clearable placeholder="请填写采购号码"/> -->
							<el-form-item label="采购号码:">
								<el-input size="small" v-model.trim="searchForm.podr_no" clearable placeholder="请填写采购号码"/>
							</el-form-item>
						</el-col>
						<el-col :md="8">
							<el-form-item label="物料名称:">
								<el-input size="small" v-model.trim="searchForm.mtrl_name" clearable placeholder="请填写物料名称"/>
							</el-form-item>
						</el-col>
						<el-col :md="8">
							<el-form-item label="是否有不通过数:">
								<el-select v-model="searchForm.pass_status" filterable placeholder="请选择" clearable size="small">
									<el-option v-for="item in passStatusList" :key="item.value" :label="item.label" :value="item.value"/>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :md="8">
							<el-form-item label="工序类型:">
								<el-select v-model="searchForm.mdrp_type" filterable placeholder="请选择" clearable size="small">
									<el-option v-for="item in mdrpTypeList" :key="item.value" :label="item.label" :value="item.value"/>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :md="8" v-if="this.$cookies.get('userInfo').acct_id === 1">
							<el-form-item label="按账套查看:">
								<el-select v-model="searchForm.acct_id" filterable placeholder="请选择账套" clearable size="small">
									<el-option v-for="item in acctList" :key="item.acct_id" :label="item.acct_no" :value="item.acct_id"/>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :md="24">
							<el-form-item label="金检时间:" label-width="114px">
								<el-date-picker
										size="small"
										v-model="searchForm.timeArray"
										type="daterange"
										range-separator="至"
										start-placeholder="开始日期"
										end-placeholder="结束日期"/>
							</el-form-item>
							<el-form-item style="margin-left: 15px">
								<el-button size="small" type="primary" icon="el-icon-search" @click="getTableDataNow()">查询</el-button>
								<el-button type="info" size="small" class="vd_export" @click="buttonRefresh()">
									<i class="el-icon-refresh-right"/>刷新
								</el-button>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<div class="vd_button_group vg_mtb_16">
			</div>
			<el-row>
				<el-col :md="24">
					<el-table ref="multiTable" v-loading="loadingFlag" :data="tableData" border stripe>
						<el-table-column type="index" label="序号" align="center"/>
						<el-table-column label="采购号码" prop="podr_no"/>
						<!-- <el-table-column label="客户货号" prop="cust_artno"/> -->
						<el-table-column label="物料类型" prop="mtrl_type" :formatter="formatMtrlType"/>
						<el-table-column label="物料名称" prop="mtrl_name"/>
						<el-table-column label="工序类型" prop="mdrp_type" :formatter="formatMdrpType"/>
						<el-table-column label="检测数量" prop="mdrp_num"/>
						<el-table-column label="不通过数" prop="mdrp_nnum"/>
						<el-table-column label="录入人" prop="stff_name"/>
						<el-table-column label="金检时间" prop="create_time" :formatter="formatDate"/>
						<el-table-column label="所属账套" prop="acct_no"  v-if="this.$cookies.get('userInfo').acct_id === 1"/>
					</el-table>
				</el-col>
			</el-row>
			<el-row>
				<el-col :md="24">
					<pubPagination :totalPage='totalPage' @changePageSearch="changePageSearch" ref="pubPagination"></pubPagination>
				</el-col>
			</el-row>
		</el-card>
	</div>
</template>

<script>
import {get} from "@api/request";
import pubPagination from "@/components/common/pubPagination";
import {acctAPI} from "@api/modules/acct";
import {mdrpAPI} from "@api/modules/mdrp";

export default {
	name: "MdrpList",
	components: {
		pubPagination,
	},
	data() {
		return {
			searchForm: {
				page_no: 1,
			},
			totalPage: 10,
			tableData: [],
			btn: {},
			loadingFlag: true,
			passStatusList: [
				{label: '否', value: 0},
				{label: '是', value: 1},
			],
			mdrpTypeList: [
				{label: '入库前', value: 1},
				{label: '机缝', value: 2},
				{label: '手工', value: 3},
				{label: '检验', value: 4},
				{label: '包装', value: 5},
			],
			mtrlTypeList: [
				{label: '加工面料', value: 0},
				{label: '辅料', value: 1},
				{label: '包材', value: 2},
				{label: '皮壳', value: 3},
				{label: '在制品', value: 4},
				{label: '成品', value: 5},
			],
			acctList: []
		};
	},
	created() {
		this.initData()
	},
	watch:{
	},
	methods: {
		initData(){
			this.loadingFlag = true
			this.getAcctData();
			this.getTableData();
		},
		// 获取账套信息
		getAcctData() {
			get(acctAPI.getAllAcctsV1,{}).then(({data}) => {
				if (data.code === 0) {
					this.acctList = data.data;
				}
			})
		},
		// 获取产品信息
		getTableData() {
			const {startTime,endTime} = this.helper.getTime(this.searchForm.timeArray)
			this.searchForm.startTime = startTime
			this.searchForm.endTime = endTime
			get(mdrpAPI.getMdrps, this.searchForm).then(({data}) => {
				if (data.code === 0) {
					this.tableData = data.data.list;
					this.totalPage = data.data.total
					this.btn = data.data.btn;
					setTimeout(() => {
						this.loadingFlag = false
					}, 500);
				}
			})
		},
		// 刷新
		buttonRefresh(){
			this.searchForm = {};
			this.searchForm.page_no = 1;
			this.loadingFlag = true
			this.$refs.pubPagination.currentPage = 1
			this.initData()
		},
		// 查询方法
		getTableDataNow(){
			this.loadingFlag = true
			this.searchForm.page_no = 1;
			this.$refs.pubPagination.currentPage = 1
			this.initData()
		},
		// 分页查询
		changePageSearch(val){
			this.searchForm.page_no = val
			this.initData()
		},
		formatMtrlType({mtrl_type}) {
			return this.mtrlTypeList.find(({value}) => value === mtrl_type).label ?? '';
		},
		formatMdrpType({mdrp_type}) {
			return this.mtrlTypeList.find(({value}) => value === mdrp_type).label ?? '';
		},
		formatMdrpNNum({mdrp_nnum}) {
			return this.passStatusList.find(({value}) => value === mdrp_nnum).label ?? '';
		},
		formatDate({create_time}) {
			return this.helper.toStringDay(create_time);
		},
	}
}
</script>

<style scoped lang="scss">
.vd_button_group{
	width: 100vw;
	position: relative;
}
</style>